import React, { useState, useEffect } from 'react'
import axios from '../utils/axios'
import Loader from '../partials/loader/loader'

import Layout from '../layout/layout'
import Banner from '../partials/banner/banner'
import contactQuery from '../utils/contactQuery'
import ContentWrap from '../components/contentWrap/contentWrap'

import Footer from '../partials/footer/footer'

const Policy = props => {
  const [respData, setRespData] = useState({})
  const [isDataFetched, setIsDataFetched] = useState(false)

  useEffect(() => {
    const fc = async () => {
      const response = await axios({
        data: JSON.stringify({
          query: `{
            policy{
                imgHeader{
                url
                }
                head
                desc
            }
            ${contactQuery}
            }`,
        }),
      })

      setRespData(response.data.data)
      setIsDataFetched(true)
    }
    fc()
  }, [])

  return (
    <>
      {<Loader isDataLoaded={isDataFetched} />}
      <Layout title="Polityka prywatności">
        {isDataFetched && (
          <>
            <Banner img={respData.policy.imgHeader.url}>regulamin</Banner>
            <ContentWrap as="main">
              <div
                dangerouslySetInnerHTML={{ __html: respData.policy.desc }}
                className="cms-text"
              />
            </ContentWrap>
          </>
        )}
      </Layout>
    </>
  )
}

export default Policy
